// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-garment-supply-and-print-js": () => import("./../../../src/pages/garment-supply-and-print.js" /* webpackChunkName: "component---src-pages-garment-supply-and-print-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-seo-page-js": () => import("./../../../src/templates/SEOPage.js" /* webpackChunkName: "component---src-templates-seo-page-js" */),
  "component---src-templates-work-type-js": () => import("./../../../src/templates/WorkType.js" /* webpackChunkName: "component---src-templates-work-type-js" */)
}

